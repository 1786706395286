import React from "react"
import * as styles from "components/member/Setting/paymentMethod.module.css"

const PaymentMethod = props => {
  return (
    <div className={styles.con}>
      <label htmlFor={props.id}>
        <input
          type="checkbox"
          id={props.id}
          name="checkbox"
          className={props.className}
          onClick={props.onClick}
          defaultChecked=""
        />

        <div id={props.id}></div>
        <p>{props.text}</p>
      </label>
    </div>
  )
}

export default PaymentMethod
