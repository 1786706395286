import React, { useState } from "react"
import Layout from "components/common/layout.js"
import SettingLayout from "components/member/Setting/settingLayout"
import paymentLogo from "images/paymentLogo.png"
import PaymentMethod from "components/member/Setting/paymentMethod"
import * as styles from "pages/member/setting/changePayment.module.css"

const ChangePaymentPage = props => {
  const paymentMethod = [
    "신용카드",
    "계좌이체",
    "휴대폰",
    "카카오페이",
    "네이버페이",
  ]

  const [next, setNext] = useState(null)
  const [currentMenu, setcurrentMenu] = useState(null)

  const onClick_ = ({ target }) => {
    if (currentMenu) {
      currentMenu.style.border = "1px solid #dfdfdf"
    }

    var labels = document.getElementsByTagName("div")
    for (var i = 0; i < labels.length; i++) {
      if (labels[i].id == target.id) {
        setcurrentMenu(labels[i])
        labels[i].style.border = "2px solid #e95c63"
      }
    }

    document.getElementsByName("checkbox").forEach(box => {
      if (target.id == box.id) {
        target.checked ? (target.checked = true) : (target.checked = false)
      } else {
        box.checked = false
      }
    })
  }

  const next_ = () => {
    document.getElementsByName("checkbox").forEach(box => {
      box.checked ? setNext(paymentMethod[box.id]) : null
    })

    return
  }

  return (
    <Layout settingHeader>
      <SettingLayout title="결제 수단 변경" />
      <div className={styles.con}>
        <img src={paymentLogo} alt={"paymentLogo"} />
        <p>결제 정보 업데이트</p>
        {next ? (
          <>
            <p>
              다음 결제 주기부터 새로 등록한 결제 수단으로 요금이 청구됩니다.
            </p>
            <div>
              <div
                className={"confirmBtn"}
                style={{ width: "268px", margin: "0" }}
              >
                완료
              </div>
            </div>
          </>
        ) : (
          <>
            <p>새로 등록할 결제 수단을 선택하세요.</p>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {paymentMethod.map((v, idx) => (
                <PaymentMethod text={v} onClick={onClick_} id={idx} key={idx} />
              ))}
            </div>

            <div>
              <div className={"confirmBtn"} onClick={next_}>
                다음
              </div>
              <div className={"cancelBtn"}>취소</div>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default ChangePaymentPage
